import logo from '../assets/distill-logo.svg';

const prefix = "http://astrowizici.st/";

export const headerTemplate = `
<style>
distill-header {
  position: relative;
  height: 60px;
  background-color: hsl(180, 5%, 15%); /* hsl(200, 60%, 15%); */
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}
distill-header .content {
  height: 70px;
  grid-column-start: text;
  grid-column-end: page;
}
distill-header a {
  font-size: 16px;
  height: 60px;
  line-height: 60px;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.8);
  padding: 22px 0;
}
distill-header a:hover {
  color: rgba(255, 255, 255, 1);
}
distill-header svg {
  width: 24px;
  position: relative;
  top: 4px;
  margin-right: 2px;
}
distill-header .nav a {
  font-size: 12px;
  text-transform: uppercase;
  margin-left: 5px;  
}
@media(min-width: 1080px) {
  distill-header {
    height: 70px;
  }
  distill-header a {
    height: 70px;
    line-height: 70px;
    padding: 28px 0;
  }
  distill-header .logo {
  }
  distill-header .nav a {
    margin-left: 24px;
  }
}
distill-header svg path {
  fill: none;
  stroke: rgba(255, 255, 255, 0.8);
  stroke-width: 3px;
}
distill-header .logo {
  font-size: 17px;
  font-weight: 700;
}
distill-header .nav {
  float: right;
  font-weight: 300;
}

</style>
<div class="content">
  <a href="/" class="logo">
    Andy Casey
  </a>
  <nav class="nav">
    <a href="${prefix}posts/">Posts</a>
    <a href="${prefix}research/">Research</a>
    <a href="${prefix}teaching/">Teaching</a>
    <a href="${prefix}talks/">Talks</a>
    <a href="${prefix}contact/">Contact</a>
  </nav>
</div>
`;

/*
    <a href="${prefix}outreach/">Outreach</a>


*/
