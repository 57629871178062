// Copyright 2018 The Distill Template Authors
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import { Template } from '../mixins/template';

import {breadcrumbsTemplate} from './distill-breadcrumbs-template';

export function breadcrumbscontent(frontMatter) {
    if (frontMatter.breadcrumbs) {
        var content = '<div class="content"><nav class="nav">';
        const N = Object.keys(frontMatter.breadcrumbs).length - 1;
        for (const [n, [text, href]] of Object.entries(frontMatter.breadcrumbs)) {
            console.log(`breadcrumbs ${n}: ${text} ${href}`);
            content += `<a href="${href}">${text}</a>`;
            if (N > n) {
                content += ` &gt;`;
            }
        }
        content += '</nav></div>';
        return content;
    }
    else {
        return ``;
    }
}


export class DistillBreadcrumbs extends HTMLElement {

  static get is() { return 'distill-breadcrumbs'; }

  set frontMatter(frontMatter) {
    this.innerHTML = breadcrumbscontent(frontMatter);
  }

}
